<template>
  <div id="not-found-page">
    <h1 class="center link-rh">PAGE NOT FOUND</h1>
  </div>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>

<style scoped>
.center {
  margin: auto;
  width: 50%;
  text-align: center;
  padding: 240px 0;
}
</style>